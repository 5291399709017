<template>
    <div v-if="show" class="tip">
        ⓘ {{ tip }}
        <div @click="show = !show" class="tip__close link">Got it!</div>
    </div>
</template>

<script>
export default {
    name: 'Tip',
    props: {
        tip: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            show: true
        }
    }
}
</script>
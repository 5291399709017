export default {
    namespaced: true,
    state: {
        currentTheme: 'Minimal',
        themes: ['Classic', 'Minimal']
    },
    getters: {
        getCurrentTheme(state) {
            return state.currentTheme.toLowerCase()
        },
        getAllThemes(state) {
            return state.themes
        }
    },
    mutations: {
        setTheme(state, payload) {
            state.currentTheme = payload
        }
    }
}
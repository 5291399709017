import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Colors.Gallery',
    },
    component: HomeView
  },
  {
    path: '/create',
    name: 'create',
    meta: {
      title: 'Create Palette',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateView.vue')
  },
  {
    path: '/palette/:id',
    name: 'palette',
    meta: {
      title: 'Palette',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/PaletteView.vue')
  },
  {
    path: '/saved',
    name: 'saved',
    meta: {
      title: 'Saved Paletts',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SavedView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not found',
    meta: {
      title: 'Page not Found',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router

export default {
    namespaced: true,
    state: {
        savedPalettes: []
    },
    getters: {
        getSavedPalettes(state) {
            return state.savedPalettes
        },
        isSaved: (state) => (id) => {
            return state.savedPalettes.includes(id)
        },
        savedPalettesCount(state) {
            return state.savedPalettes.length
        }
    },
    mutations: {
        savePalette(state, id) {
            state.savedPalettes.unshift(id)
            localStorage.setItem('savedPalettes', JSON.stringify(state.savedPalettes));
        },
        removePalette(state, id) {
            let index = state.savedPalettes.findIndex(n => n === id);
            state.savedPalettes.splice(index, 1)
            localStorage.setItem('savedPalettes', JSON.stringify(state.savedPalettes));
        },
        setPalettes(state, palettes) {
            state.savedPalettes = palettes
        }
    }
}
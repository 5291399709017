<template>
    <nav class="nav-wrapper">
        <div class="nav">

            <router-link to="/" class="nav__logo">Colors.Gallery</router-link>
            <img @click="isMenuOpened = !isMenuOpened" src="../assets/menu.svg" alt="" class="nav__menu-btn">

            <ul @click="isMenuOpened = false" class="nav__links" :class="menuClass">

                <router-link to="/" class="nav__link">Home</router-link>
                <router-link to="/saved" class="nav__link">Saved Palettes ({{ savedCount }})</router-link>
                <router-link to="/create" class="nav__link">Create Palette

                </router-link>

            </ul>
        </div>
    </nav>
</template>

<script>
import SolarGalleryAddBold from '~icons/solar/gallery-add-bold';

export default {
    components: { SolarGalleryAddBold },
    name: 'Navigation',
    data() {
        return {
            isMenuOpened: false
        }
    },
    computed: {
        savedCount() {
            return this.$store.getters['palettes/savedPalettesCount']
        },
        menuClass() {
            return this.isMenuOpened ? 'df' : 'dn'
        }
    }
}

</script>
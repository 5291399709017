export default {
    namespaced: true,
    state: {
        notifications: [

        ],
        nextId: 0
    },
    mutations: {
        addNotification(state, payload) {
            state.notifications.push({
                content: payload,
                id: state.nextId
            })
            state.nextId++
        },
        killNotification(state, id) {
            let index = state.notifications.map(x => {
                return x.id;
            }).indexOf(id);
            state.notifications.splice(index, 1)
        }
    },
    getters: {
        getAllNotifications(state) {
            return state.notifications
        }
    },
    actions: {

    }
}
const tags = [
    { title: 'Red', color: '#E72F26' },
    { title: 'Orange', color: '#FF8B36' },
    { title: 'Yellow', color: '#E9E973' },
    { title: 'Lime', color: '#BAE475' },
    { title: 'Green', color: '#67B55B' },
    { title: 'Cyan', color: '#60D2D2' },
    { title: 'Azure', color: '#2098DC' },
    { title: 'Blue', color: '#0000FF' },
    { title: 'Violet', color: '#9763C6' },
    { title: 'Magenta', color: '#BF63C6' },
    { title: 'Rose', color: '#FE4D82' },
    { title: 'Beige', color: '#FFD1A6' },
    { title: 'Brown', color: '#5C2C00' }
];

export { tags }
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBCPOqlKamAU8Q96ZLi2aDGIxxyYC-MYew",
    authDomain: "colorgallery-77c79.firebaseapp.com",
    projectId: "colorgallery-77c79",
    storageBucket: "colorgallery-77c79.appspot.com",
    messagingSenderId: "679597405422",
    appId: "1:679597405422:web:3d745fce19f2c48ad1efff",
    measurementId: "G-F93GRXSVHF",
    
    storageBucket: "gs://colorgallery-77c79.appspot.com"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

// Get a reference to the storage service, which is used to create references in your storage bucket
const storage = getStorage();

const db = getFirestore(app);

const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider('6LeoABEqAAAAAP8_OOCH7-18iTIIvvvFcHNsG_Lf'),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

export { storage, db }
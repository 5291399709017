import { createStore } from 'vuex'
import palettes from '@/store/palettes'
import notifications from '@/store/notifications'
import themes from '@/store/themes'


export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    palettes,
    notifications,
    themes
  }
})

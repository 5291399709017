<template>
    <div class="floating dropdown-trigger">
        <SolarCodeScanBold class="floating__icon"></SolarCodeScanBold>
        <div class="dropdown">
            <div class="dropdown__title">Theme</div>
            <div @click="changeTheme(theme)" v-for="theme in allThemes" class="dropdown__item">{{ theme }}</div>
        </div>
    </div>
</template>

<script>
import SolarCodeScanBold from '~icons/solar/code-scan-bold';

export default {
    name: 'ThemeSettings',
    components: { SolarCodeScanBold },
    computed: {
        allThemes() {
            return this.$store.getters['themes/getAllThemes']
        }
    },
    methods: {
        changeTheme(theme) {
            this.$store.commit('themes/setTheme', theme)
        }
    }
}

</script>
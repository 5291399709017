<template>
    <div class="notification"> {{ content }}</div>
</template>

<script>

export default {
    name: 'Notification',
    props: {
        content: {
            type: String,
            required: true
        },
        id: {
            required: true,
            type: Number
        }
    },
    methods: {
        kill() {
            this.$store.commit('notifications/killNotification', this.id)
        }
    },
    mounted() {
        setTimeout(() => {
            this.kill()
        }, 2200)
    }
}

</script>
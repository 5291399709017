<template>

  <div class="container mt_xxl mb_l">
    <div class="title-block">
      <span class="tagline">More than 3000 great palettes</span>
      <h1 class="title_xl">Real World <br> Colors Inspiration </h1>
      <p class="text">Explore, save and create the best color palettes</p>

      <div class="tags">
        <div v-for="tag in tags" class="tag" :class='{ "tag_selected": (tag.title == selectedTag) }'
          @click="selectTag(tag.title)">
          <div :style="{ background: tag.color }" class="tag__color"></div>
          <div class="tag__title">{{ tag.title }}</div>
        </div>
      </div>

    </div>

    <div class="filters mt">
      <p class="text">{{ selectedTag || 'All Palettes' }}</p>

      <div class="select-wrapper">
        <label class="select-label">
          <select @change="reloadPalettes" v-model="sortBy" id="sortPalettes" class="select">
            <option value="created">Newest</option>
            <option value="saves">Popular</option>
          </select>
          <SolarRoundSortVerticalBold class="select-icon" style="font-size: 24px;" />
        </label>
      </div>


    </div>

    <section v-if="palettes.length" class="palettes mt">

      <router-link v-for="palette in palettes" :to="'palette/' + palette.id">
        <Palette :palette="palette">
        </Palette>
      </router-link>

    </section>
    <p v-else-if="!isLoading" class="text tac mt_l">No Plalettes Yet</p>

    <Preloader v-if="isLoading" class="mt_l"></Preloader>
    <div v-else-if="lastVisible" @click="loadPalettes" class="btn tac mt_l">Load More</div>

  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Palette from '@/components/Palette.vue'
import Preloader from '@/components/Preloader.vue'
import SolarRoundSortVerticalBold from '~icons/solar/round-sort-vertical-bold';
import { tags } from '@/utils/tags'

import { db } from '@/firebase';
import { doc, getDocs, query, collection, limit, orderBy, startAfter, where } from "firebase/firestore";

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    Palette,
    Preloader,
    SolarRoundSortVerticalBold
  },
  data() {
    return {
      palettes: [],
      lastVisible: false,
      isLoading: false,
      selectedTag: false,
      sortBy: 'created'
    }
  },
  watch: {
    selectedTag() {
      this.reloadPalettes()
    }
  },
  computed: {
    tags() {
      // return tags.slice(0, 7);
      return tags
    }
  },
  methods: {
    async loadPalettes() {
      this.isLoading = true

      let queryPalettes
      let whereTagsQuerry

      if (this.selectedTag) {
        whereTagsQuerry = where("tags", "array-contains", this.selectedTag)
      }
      else {
        whereTagsQuerry = false
      }
      console.log(whereTagsQuerry)

      if (this.lastVisible) {
        queryPalettes = query(collection(db, "palettes"),
          whereTagsQuerry,
          orderBy(this.sortBy, "desc"),
          startAfter(this.lastVisible),
          limit(9));
      }
      else {
        queryPalettes = query(collection(db, "palettes"),
          whereTagsQuerry,
          limit(9),
          orderBy(this.sortBy, "desc"));
      }

      const documentSnapshots = await getDocs(queryPalettes);

      let palettes = []
      let imagesToLoad = []

      documentSnapshots.forEach(async (doc) => {

        // Push To Palettes Array
        // this.palettes.push({ ...doc.data(), id: doc.id })

        palettes.push({ ...doc.data(), id: doc.id })
        imagesToLoad.push(doc.data().imageUrl)
      });

      // ______Preload Images __________

      const promiseArray = []; // create an array for promises

      for (let [index, imageUrl] of imagesToLoad.entries()) {
        promiseArray.push(new Promise(resolve => {
          fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => {
              const url = URL.createObjectURL(blob);
              palettes[index].imageUrl = URL.createObjectURL(blob)
              console.log(blob)
              resolve()
            });
        }));
      }

      await Promise.all(promiseArray);
      this.palettes = this.palettes.concat(palettes)

      // ______/Preload Images __________

      console.log(documentSnapshots.empty)
      this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

      this.isLoading = false
    },
    selectTag(tag) {
      if (this.selectedTag !== tag) {
        this.selectedTag = tag
        this.$router.push({ path: '/', query: { tag } })
      }
      else {
        this.selectedTag = false
        this.$router.push({ path: '/' })
      }

    },
    reloadPalettes() {
      this.palettes = []
      this.lastVisible = false
      console.log(this.selectedTag)
      this.loadPalettes()
    }
  },
  async mounted() {
    if (this.$route.query.tag) {
      this.selectedTag = this.$route.query.tag
    }
    this.loadPalettes()
    console.log(tags)
  }
}
</script>

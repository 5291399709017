<template>
    <div class="tips">
        <Tip v-for="tip in tips" :tip="tip"></Tip>
    </div>
</template>

<script>
import Tip from './Tip.vue';

export default {
    name: 'TipsPull',
    components: {
        Tip
    },
    data() {
        return {
            tips: [
                'Click the HEX code to copy to clipboard!'
            ]
        }
    }
}
</script>
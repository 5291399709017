<template>
  <Navigation></Navigation>
  <NotificationsPull></NotificationsPull>
  <!-- <TipsPull></TipsPull> -->
  <ThemeSettings></ThemeSettings>

  <!-- <router-view /> -->

  <router-view v-slot="{ Component, route }">
    <transition appear name="slide-fade">
      <component :key="route.name" :is="Component" />
    </transition>
  </router-view>

</template>

<script>
import Navigation from '@/components/Navigation.vue';
import NotificationsPull from '@/components/NotificationsPull.vue'
import TipsPull from './components/TipsPull.vue';
import ThemeSettings from './components/ThemeSettings.vue'

export default {
  components: {
    Navigation, NotificationsPull, TipsPull, ThemeSettings
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(1em);
  opacity: 0;
}
</style>

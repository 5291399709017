import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './styles/style.css'

createApp(App).use(store).use(router).mount('#app')

let savedPalettes = JSON.parse(localStorage.getItem("savedPalettes"));
if (savedPalettes) {
    store.commit('palettes/setPalettes', savedPalettes)
}

<template>
    <div class="palette-wrapper">
        <!-- <div v-if="!paletteBlob" class="palette-skeleton"></div> -->

        <div ref="palette" class="palette" :class="'palette_' + $store.getters['themes/getCurrentTheme']">

            <img v-if="previewImageUrl" :src="previewImageUrl" :data-pin-url="pinUrl"
                :data-pin-description="pinDesription" class="palette__preview-img" alt="">

            <div class="palette__watermark">colors.gallery</div>

            <div :style="{ 'background-image': 'url(' + palette.imageUrl + ')' }" class="palette__image"
                data-pin-nopin="true" nopin></div>

            <TransitionGroup appear name="slide-fade" tag="div" @after-enter="createPreviewImage"
                class="palette__colors">
                <ColorBox @colorChange="((newColor) => $emit('colorChange', { newColor, index: i }))"
                    v-for="color, i in palette.colors" :color="color" :key="i">
                </ColorBox>
            </TransitionGroup>

        </div>

        <div v-if="!isPreview" class="palette__actions">

            <div @click.prevent="save" :class="[isSaved ? 'palette__action_saved' : '', 'palette__action']">

                <SolarHeartBold v-if="isSaved" />
                <SolarHeartLinear style="stroke-width: 0.1" v-else />

                <!-- {{ saveTitle }} -->
                {{ palette.saves }}
            </div>

            <div @click.prevent="download" class="palette__action">
                <SolarDownloadMinimalisticLinear /> Download
            </div>
        </div>

    </div>

</template>

<script>
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';

import { db } from '@/firebase';
import { doc, runTransaction } from "firebase/firestore";
import ColorBox from '@/components/ColorBox.vue'

import SolarHeartLinear from '~icons/solar/heart-linear';
import SolarHeartBold from '~icons/solar/heart-bold';
import SolarDownloadMinimalisticLinear from '~icons/solar/download-minimalistic-linear';

export default {
    name: 'Platte',
    components: { ColorBox, SolarHeartLinear, SolarHeartBold, SolarDownloadMinimalisticLinear },
    props: {
        palette: {
            type: Object,
            required: true
        },
        isPreview: {
            type: Boolean,
            default: false
        },
        isOwnPage: false,
    },
    data() {
        return {
            isLoading: false,
            previewImageUrl: false,
            paletteBlob: false,
        }
    },
    computed: {
        isSaved() {
            return this.$store.getters['palettes/isSaved'](this.palette.id)
        },
        saveTitle() {
            return this.isSaved ? 'Saved' : 'Save'
        },
        pinUrl() {
            return 'https://www.colors.gallery/palette/' + this.palette.id
        },
        pinDesription() {
            let description = ""
            this.palette.tags.forEach(tag => {
                description = description + tag + " Color, "
            })
            description = description + 'Palette. Visit Colors.Gallery website for more than 3000 color palettes'
            return description
        }
    },
    methods: {

        async save() {
            if (this.isLoading) return

            if (!this.isSaved) {
                this.palette.saves++
                this.$store.commit('palettes/savePalette', this.palette.id)
            }
            else {
                this.palette.saves--
                this.$store.commit('palettes/removePalette', this.palette.id)
            }

            const paletteRef = doc(db, `palettes/${this.palette.id}`);

            try {
                this.isLoading = true
                await runTransaction(db, async (transaction) => {

                    const palette = await transaction.get(paletteRef);
                    if (!palette.exists()) {
                        throw "Document does not exist!";
                    }

                    let newSaves;

                    if (this.isSaved) {
                        newSaves = palette.data().saves + 1;
                    }
                    else {
                        newSaves = palette.data().saves - 1;
                    }

                    transaction.update(paletteRef, { saves: newSaves });
                    console.log(newSaves)
                });
                console.log("Transaction successfully committed!");
                this.isLoading = false
            } catch (e) {
                console.log("Transaction failed: ", e);
            }
        },
        download() {
            this.$store.commit('notifications/addNotification', 'Preparing your Palette....')
            document.documentElement.style.cursor = 'wait';
            html2canvas(this.$refs.palette, {
                useCORS: true,
            }).then(canvas => {

                let ctx = canvas.getContext('2d');
                ctx.webkitImageSmoothingEnabled = true;
                ctx.mozImageSmoothingEnabled = true;
                ctx.imageSmoothingEnabled = true;
                console.log(ctx)

                canvas.toBlob((blob) => {
                    console.log(URL.createObjectURL(blob))
                    saveAs(blob, `palette`);
                    document.documentElement.style.cursor = 'default';

                });
            });
        },
        createPreviewImage() {
            console.log('craete img')
            if (this.isOwnPage) {
                html2canvas(this.$refs.palette, {
                    useCORS: true,
                }).then(canvas => {
                    canvas.toBlob((blob) => {
                        this.paletteBlob = blob
                        this.previewImageUrl = URL.createObjectURL(blob)
                    });
                });
            }
        },
    },
    mounted() {

    }
}
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: scale(0.9);
    opacity: 0;
}
</style>

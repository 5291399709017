function getContrastYIQ(hexcolor) {
    // Remove the hash at the start if it's there
    if (hexcolor[0] === '#') {
        hexcolor = hexcolor.slice(1);
    }

    // Convert hex to RGB
    let r = parseInt(hexcolor.substring(0, 2), 16);
    let g = parseInt(hexcolor.substring(2, 4), 16);
    let b = parseInt(hexcolor.substring(4, 6), 16);

    // Calculate the luminance
    let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    // Return 'black' for light backgrounds and 'white' for dark backgrounds
    return (yiq >= 128) ? 'black' : 'white';
}

export default getContrastYIQ


<!-- 'box-shadow': '1px 10px 50px -16px' + color.code -->

<template>
    <!-- <Transition appear name="slide-fade"> -->
    <div @click.prevent="copyColor" :style="{ 'background-color': colorCode }" class="color-box"
        :class="'color-box_' + $store.getters['themes/getCurrentTheme']">

        <h3 :style="{ 'color': getContrastYIQ(colorCode) }" class="color-box__title"
            :class="'color-box__title_' + $store.getters['themes/getCurrentTheme']">
            {{ color.title }}
        </h3>
        <p :style="{ 'color': getContrastYIQ(colorCode) }" class="color-box__code"> HEX: {{ colorCode
            }}
        </p>

        <div v-show="editable" class="color-box__action">
            <label class="color-action" for="head">
                <input v-model="colorCode" @change="$emit('colorChange', colorCode)" class="color-action__input"
                    @click.stop type="color" id="color" name="color" />
                <span class="color-action__icon">
                    <SolarWaterdropsBold :style="{ 'color': getContrastYIQ(colorCode) }" />
                </span>
            </label>
        </div>

        <div v-show="!editable" class="color-box__action">
            <span class="color-action__icon color-action__icon_small">
                <SolarCopyBold :style="{ 'color': getContrastYIQ(colorCode) }" />
            </span>

        </div>

    </div>
    <!-- </Transition> -->
</template>

<script>
import getContrastYIQ from '@/utils/getContrast';
import SolarWaterdropsBold from '~icons/solar/waterdrops-bold';
import SolarCopyBold from '~icons/solar/copy-bold';

export default {
    name: 'ColorBox',
    components: { SolarWaterdropsBold, SolarCopyBold },
    props: {
        color: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            colorCode: this.$props.color.code
        }
    },
    computed: {
        editable() {
            return this.$route.name === 'create'
        }
    },
    methods: {
        getContrastYIQ,
        copyColor() {
            navigator.clipboard.writeText(this.color.code).then(() => {
                this.$store.commit('notifications/addNotification', 'Hex Code Copied to Clipboard')
            }, function (err) {
                console.error('Async: Could not copy text: ', err);
            });
        },
        addAlpha(color, opacity) {
            // coerce values so it is between 0 and 1.
            let _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
            return color + _opacity.toString(16).toUpperCase();
        },
        changeColor(e) {
            console.log(e)
        }
    }
}
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: scale(0.9);
    opacity: 0;
}
</style>
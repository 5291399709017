<template>


    <TransitionGroup class="notifications" name="list" tag="div">
        <Notification v-for="notification in notifications" :content="notification.content" :id="notification.id"
            :key="notification.id">
        </Notification>
    </TransitionGroup>



</template>

<script>
import Notification from '@/components/Notification.vue'

export default {
    name: 'Notifications',
    components: { Notification },
    data() {
        return {

        }
    },
    computed: {
        notifications() {
            return this.$store.getters['notifications/getAllNotifications']
        }
    }
}
</script>

<style>
.list-move,
/* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
    transition: all 0.2s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
    position: absolute;
}
</style>